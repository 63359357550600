<template>
  <!-- <v-app> -->
    <div>
      <!-- Menu::Start -->
      <div class="container">
        <div class="d-flex justify-content-center mb-5">
          <h3>Desa {{ village_info.name }}</h3>
        </div>
        <table>
          <tr>
            <td class="item-custom py-1" style="width: 320px">Kota/Kabupaten</td>
            <td class="item-custom py-1 text-center" style="width: 30px">:</td>
            <td class="item-custom py-1 font-weight-bold">{{ config.city }}</td>
          </tr>
          <tr>
            <td class="item-custom py-1" style="width: 320px">Kecamatan</td>
            <td class="item-custom py-1 text-center" style="width: 30px">:</td>
            <td class="item-custom py-1 font-weight-bold">{{ config.district }}</td>
          </tr>
          <tr>
            <td class="item-custom py-1" style="width: 320px">Camat</td>
            <td class="item-custom py-1 text-center" style="width: 30px">:</td>
            <td class="item-custom py-1 font-weight-bold">{{ config.district_head_name }}</td>
          </tr>
          <tr>
            <td class="item-custom py-1" style="width: 320px">
              Kontak Camat (WA)
            </td>
            <td class="item-custom py-1 text-center" style="width: 30px">:</td>
            <td class="item-custom py-1 font-weight-bold">{{ config.district_head_reg_number }}</td>
          </tr>
          <tr>
            <td class="item-custom py-1" style="width: 320px">Desa</td>
            <td class="item-custom py-1 text-center" style="width: 30px">:</td>
            <td class="item-custom py-1 font-weight-bold">{{ config.village_name }}</td>
          </tr>
          <tr>
            <td class="item-custom py-1" style="width: 320px">Kepala Desa</td>
            <td class="item-custom py-1 text-center" style="width: 30px">:</td>
            <td class="item-custom py-1 font-weight-bold">{{ config.village_chief_name }}</td>
          </tr>
          <tr>
            <td class="item-custom py-1" style="width: 320px">
              Kontak Kepala Desa (WA)
            </td>
            <td class="item-custom py-1 text-center" style="width: 30px">:</td>
            <td class="item-custom py-1 font-weight-bold">{{ config.village_chief_reg_number }}</td>
          </tr>
          <tr>
            <td class="item-custom py-1" style="width: 320px">Wakil Kepala Desa</td>
            <td class="item-custom py-1 text-center" style="width: 30px">:</td>
            <td class="item-custom py-1 font-weight-bold">{{ config.deputy_village_chief_name }}</td>
          </tr>
          <tr>
            <td class="item-custom py-1" style="width: 320px">
              Kontak Wakil Kepala Desa (WA)
            </td>
            <td class="item-custom py-1 text-center" style="width: 30px">:</td>
            <td class="item-custom py-1">
              {{ config.deputy_village_chief_reg_number }}
            </td>
          </tr>
          <tr>
            <td class="item-custom py-1" style="width: 320px">Kode POS</td>
            <td class="item-custom py-1 text-center" style="width: 30px">:</td>
            <td class="item-custom py-1 font-weight-bold">{{ config.postal_code }}</td>
          </tr>

          <tr>
            <td class="item-custom py-1" style="width: 320px">Jumlah Penduduk</td>
            <td class="item-custom py-1 text-center" style="width: 30px">:</td>
            <td class="item-custom py-1">
              {{
                parseInt(village_info.number_of_citizen).toLocaleString("id-ID")
              }}
              Jiwa
            </td>
          </tr>
          <tr>
            <td class="item-custom py-1" style="width: 320px">Luas Wilayah Desa</td>
            <td class="item-custom py-1 text-center" style="width: 30px">:</td>
            <td class="item-custom py-1">
              {{
                parseInt(village_info.area).toLocaleString("id-ID")
              }}
            </td>
          </tr>
          <tr>
            <td class="item-custom py-1" style="width: 320px">Luas Wilayah Pertanian Desa</td>
            <td class="item-custom py-1 text-center" style="width: 30px">:</td>
            <td class="item-custom py-1">
              {{
                parseInt(village_info.agriculture_area).toLocaleString("id-ID")
              }}
            </td>
          </tr>
          <tr>
            <td class="item-custom py-1" style="width: 320px">Komoditas Unggulan</td>
            <td class="item-custom py-1 text-center" style="width: 30px">:</td>
            <td class="item-custom py-1">
              {{
                village_info.best_commodity
              }}
            </td>
          </tr>
          <tr>
            <td class="item-custom py-1" style="width: 320px">Tempat Wisata Unggulan</td>
            <td class="item-custom py-1 text-center" style="width: 30px">:</td>
            <td class="item-custom py-1">
              {{
                village_info.best_tourism
              }}
            </td>
          </tr>
          <tr>
            <td class="item-custom py-1" style="width: 320px">Produk Unggulan Desa</td>
            <td class="item-custom py-1 text-center" style="width: 30px">:</td>
            <td class="item-custom py-1">
              {{
                village_info.best_selling_commodity
              }}
            </td>
          </tr>
          
          <tr>
            <td class="item-custom py-1" style="width: 320px">Alamat Desa</td>
            <td class="item-custom py-1 text-center" style="width: 30px">:</td>
            <td class="item-custom py-1 font-weight-bold">{{ village_info.address }}</td>
          </tr>
          <tr>
            <td class="item-custom py-1" style="width: 320px">Tentang Desa</td>
            <td class="item-custom py-1 text-center" style="width: 30px">:</td>
            <td class="item-custom py-1" v-html="village_info.description"></td>
          </tr>
        </table>
      </div>
    </div>
    <!-- Menu::End -->
  <!-- </v-app> -->
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import { setConfiguration } from "@/core/services/jwt.service.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  data() {
    return {
      village_info: {},
      config: {},
      interval: null,
      time: null,

      modal2: false,
      hamlets: [],
    };
  },
  methods: {
    async getSetting() {
      let response = await module.get("settings-no-auth/1");
      console.log(
        "get setting no auth ============================================================================================"
      );
      if (response != null) {
        this.config = response;
        setConfiguration(JSON.stringify(response));
      }
    },
    async getInfo() {
      let response = await module.get("village-info/1");
      if (response != null) {
        this.village_info = response;
        console.log(this.village_info, "this.village_info");
      }
    },
  },
  mounted() {
    this.getSetting();
    this.getInfo();
    // this.getHamletHeadOption();
  },
  beforeDestroy() {
    // prevent memory leak
    clearInterval(this.interval);
  },
  created() {
    // update the time every second
    this.interval = setInterval(() => {
      // Concise way to format time according to system locale.
      // In my case this returns "3:48:00 am"
      this.time = Intl.DateTimeFormat("en-GB", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format();
    }, 1000);
  },
};
</script>

<style scoped>
.curved-header {
  height: 270px;
  width: 1280px;
  border: none;
  -webkit-box-shadow: inset 0px -1px 0px black;
  box-shadow: inset 0px -1px 0px black;
  margin-bottom: 4px;
  background: white;
  -webkit-box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
  -moz-box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
  box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
}

.curved-footer {
  height: 230px;
  width: 1280px;
  border: none;
  /* -webkit-box-shadow: inset 0px -1px 0px black; */
  box-shadow: inset 0px -1px 0px black;
  margin-bottom: 4px;
  background: white;
  -webkit-box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
  -moz-box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
  box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
}

.sample1 {
  border-radius: 0% 0% 150% 150%/0% 0% 70% 70%;
  width: 100%;
}
.sample2 {
  border-radius: 70% 70% 0% 0%/30% 30% 100% 100%;
  position: absolute;
  bottom: -5px;
  width: 100%;
}

.form-control-lg {
  height: 70px;
}
</style>

<style scoped>
.vue-treeselect--single .vue-treeselect__input-container {
  font-size: inherit;
  height: 70px !important;
}

.vue-treeselect--single .vue-treeselect__input {
  width: 100%;
  height: 70px !important;
}
.item-custom{
  font-size: 20px;

}
</style>
